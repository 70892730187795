import { render, staticRenderFns } from "./Tencent.vue?vue&type=template&id=60fd9844&scoped=true&"
import script from "./Tencent.vue?vue&type=script&lang=js&"
export * from "./Tencent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60fd9844",
  null
  
)

export default component.exports