<template>
  <div>
    <leaflet-panel
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :show-detail-btn="showDetailBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`平台架构`"
               :en_title="`Platform Architecture`"></Paragraph>
    <div style="width:80%;margin-left: 10%">
      <img src="@/assets/image/chain/baas/tencent/tencentimg.png" style="width: 100%">
    </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`平台优势`"
               :en_title="`Advantage Of Platform`"></Paragraph>
    <Titlestrlist :datalist="datalist"></Titlestrlist>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/star.svg`)"
               :title="`应用场景及方案价值`"
               :en_title="`Application Scenarios And Solution Values`"></Paragraph>
    <div style="display: flex;flex-direction: row;margin-left: 10%;margin-right: 10%">
      <Lettertitleblocklv2 :datalist="datalistx"
                        style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblocklv2>
    </div>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';
import Lettertitleblocklv2 from '@/components/chain/basic/common/lettertitleblocklv2';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Titlestrlist,
    Lettertitleblocklv2,
  },
  data() {
    return {
      datalistx: [
        {
          title: '数字资产',
          imgsrc: 'A',
          desc: ['应用于共享积分、优惠券、数字货币、', '股权登记等业务场景'],
          list: [
            {
              title: '资产安全',
              content: '资产数据多方记录，并不可篡改，多重保障用户资产安全',
            },
            {
              title: '监管合规',
              content: '监管节点实施掌握资产流通状态，流通过程全程可追溯',
            },
            {
              title: '流通便利',
              content: '资产上链发行后，可拆散流通，并在流通环节不依赖发行方',
            },
          ],
        }, {
          title: '鉴证证明',
          imgsrc: 'B',
          desc: ['应用于版权/所有权保护、司法文件保全、', '公益捐赠、个人及企业证明等业务场景'],
          list: [
            {
              title: '记录安全',
              content: '证明内容公开上链，不可篡改',
            },
            {
              title: '验证便捷',
              content: '多方存证，随时可查验，降低使用成本',
            },
            {
              title: '安全合规',
              content: '符合监管流程，保障多方权益',
            },
          ],
        }, {
          title: '共享账本',
          imgsrc: 'C',
          desc: ['应用于机构间清算、银行保理、机构间联合贷', '款、供应链金融、跨境汇款等业务场景'],
          list: [
            {
              title: '提升效率',
              content: '业务参与方可从区块链上直接读取，',
            },
            {
              title: '',
              content: '降低多方系统间耦合性，提升业务效率',
            },
            {
              title: '安全升级',
              content: '通过多方数据核对，为用户提供安全可靠、永不丢失的数据记录',
            },
            {
              title: '数据共享',
              content: '搭建可靠数据平台，改变业务数据获取方式，',
            },
            {
              title: '',
              content: '区块链+大数据助力企业发展',
            },
          ],
        },
      ],
      datalist: [
        { title: '>  性能领先', content: '基于微信红包亿级用户并发、分布式处理经验，提供每秒万级处理能力。轻松支撑金融级场景。' },
        { title: '>  灵活高效', content: '底层采用数据库结构，同时支持数据异构，支持多种共识算法柔性切换。' },
        { title: '>  快速接入', content: '一键安装，高效易用，大幅降低业务开发、迁移及维护工作量，节约人员成本。' },
        { title: '>  合规安全', content: '完善的用户、密钥、权限管理及隔离处理既保障数据、账户信息安全，又满足实时穿透的合规监管。' },
      ],
      title: '腾讯TBaaS服务',
      body:
          'TBaaS开放平台为腾讯区块链提供的企业级区块链应用开放平台，帮助客户从业务的角度理解区块链，'
          + '专注于帮助企业快速搭建上层区块链应用场景。TBaaS开放平台整体架构设计分为两部分：链管理平台和节点管理平台。',
      image: require('@/assets/image/chain/baas/tencent/tencent.jpg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/baas/tencent/1.jpg'),
        require('@/assets/image/chain/baas/tencent/2.jpg'),
        require('@/assets/image/chain/baas/tencent/3.jpg'),
      ],
    };
  },
};
</script>

<style scoped>
</style>
