<template>
  <div style="width: 100%">
  <div v-for="item in datalist" :key="item.index" style="width: 33%">
    <Lettertitle :title="item.title"
                 :imgsrc="item.imgsrc"></Lettertitle>
    <div>
      <div>
        <ul style="list-style: none;margin-top: 1rem;margin-bottom: 1rem;
      padding: 0;">
          <li style="font-size: 1rem; line-height: 2rem;font-weight: 600"
              v-for="ditem in item.desc" :key="ditem.index">
            {{ditem}}
          </li>
        </ul>
      </div>
      <ul style="list-style: none;margin-top: 1rem;margin-bottom: 6rem;
      padding: 0;">
        <li style="font-size: 0.9rem; line-height: 2rem;"
            v-for="citem in item.list" :key="citem.index">
          <span style="color: #278AFB">{{citem.title}}</span>
          {{citem.content}}
        </li>
      </ul>
    </div>
  </div>
  </div>
</template>

<script>

import Lettertitle from '@/components/chain/basic/common/lettertitle';

export default {
  name: 'lettertitleblock',
  components: {
    Lettertitle,
  },
  props: ['datalist'],
};
</script>

<style scoped>

</style>
